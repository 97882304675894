import * as Amplitude from 'amplitude-js';

export type AnalyticsEvent = {
  name: string;
  payload?: Record<string, any>;
};

export interface AnalyticsClient {
  emit({ name, payload }: AnalyticsEvent): void;
  setCustomer(alias: string): void;
  setProperties(props: Record<string, any>): void;
}

class AmplitudeClient implements AnalyticsClient {
  initialized = false;
  private instance: Amplitude.AmplitudeClient | undefined;

  async initialize(
    key: string | undefined,
    analyticsOptions: Amplitude.Config
  ) {
    if (this.initialized || !key) {
      return;
    }

    this.instance = Amplitude.getInstance();
    this.instance.init(key, null, {
      ...analyticsOptions,
    });

    const identity = new Amplitude.Identify();
    this.instance.identify(identity);

    this.initialized = true;
  }

  emit({ name, payload }: AnalyticsEvent) {
    this.instance.logEvent(name, payload);
  }

  setCustomer(alias: string) {
    this.instance.setGroup('customerAlias', alias);
  }

  setProperties(props: Record<string, any>) {
    return this.instance.setUserProperties(props);
  }
}

class LoggingClient implements AnalyticsClient {
  emit({ name, payload }: AnalyticsEvent) {
    console.log(`Event: ${name}`, payload || 'no payload');
  }
  setCustomer(alias: string) {
    console.log(`Customer : ${alias}`);
  }
  setProperties(props: Record<string, any>) {
    console.log(`User properties`, props);
  }
}

export type AnalyticsClientFacade = Omit<AnalyticsClient, 'initialize'>;

export let client: AnalyticsClientFacade;

export const create = async (config: {
  appVersion: string;
  customerAlias: string;
  options?: Partial<Amplitude.Config>;
}): Promise<AnalyticsClientFacade> => {
  const amplitude = new AmplitudeClient();

  await amplitude.initialize('f3b28008aa76ff4d4bc54140b28d9069', {
    forceHttps: true,
    secureCookie: true,
    sameSiteCookie: 'None',
    includeUtm: true,
    includeGclid: true,
    includeFbclid: true,
    includeReferrer: true,
    ...config.options,
  });

  amplitude.setCustomer(config.customerAlias);

  const clients: AnalyticsClient[] = [
    window.localStorage.getItem('analyticsLogger') && new LoggingClient(),
    amplitude,
  ].filter(Boolean);

  const emit = (event: AnalyticsEvent) => {
    event.payload = event.payload || {};
    event.payload.appVersion = config.appVersion;
    clients.forEach((client) => {
      client.emit(event);
    });
  };

  const setProperties = (props: Record<string, any>) => {
    clients.forEach((client) => {
      client.setProperties(props);
    });
  };

  const setCustomer = (alias: string) => {
    clients.forEach((client) => {
      client.setCustomer(alias);
    });
  };

  emit({ name: 'analytics initialized' });

  client = {
    emit,
    setProperties,
    setCustomer,
  };

  return client;
};
